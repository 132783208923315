/*@import "node_modules/bootstrap/scss/bootstrap";*/
/*@import "abstracts/functions";
@import "_base/animations";
@import "layout/header";*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import "components/icons.css";
@import "abstracts/variables";
@import "abstracts/mixins";
@import "_base/base";
@import "_base/utilities";
@import "_base/typography";
@import "components/forms";
@import "components/button";
@import "layout/header";
@import "pages/landing-page";


/*@import "components/_forms";*/
.cdk-overlay-container { position: fixed; top: 0; bottom: 0; z-index: 999; }
.cdk-global-overlay-wrapper .mat-dialog-container {
  padding: 0 !important;
  width: 0 !important;
}
