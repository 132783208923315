

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) { // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) { // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font:1.4rem/1.7 'Roboto', Arial, sans-serif;;
  letter-spacing: 0; color:#000;
   margin: 0; padding: 0; background: #eee;
}
.container{max-width:1200px; }
section{ background:#fff; padding: 20px 15px;
h2{padding-top:0; margin-bottom: 20px;}
}
nav{display: block}
ul{list-style: none; padding: 0; margin: 0}
.page-container{background:$color-grey-light-2; padding:15px 15px 0 15px;
}
