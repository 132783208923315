/* Normal Fonts */
h1, h2, h3, h4, h5, h6,
label, .tooltip-inner, dt{font-weight: normal}

h1{font-size:4rem;color: $color-dark-blue}

h2{color: $color-dark-blue; font-size: 2rem; font-weight: normal; padding: 20px 15px 10px 0px;
  border-bottom: 1px solid #ddd;
  margin: 0 0 30px 0;}
.help-text{font-size:1.2rem; color: $color-black; opacity: .5; line-height: 20px; }

h3{color: $color-dark-blue; font-size: 1.8rem; padding-top:20px}

h5{font-size: 1.4rem; color: $color-dark-blue;}

.a-disabled{  pointer-events: none; opacity: .5;}
.no-cursor{cursor: not-allowed;}


