.mt-100{margin-top: 100px}
.mt-60{margin-top: 60px}
.pd-t-0{padding-top: 0}
.pd-t-60{padding-top: 60px;}

//Input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



//custome dropdown list
datalist{
  border: 1px solid #ddd;
  border-radius: 0 0 3px 3px;
  border-top:0; position: absolute; top:36px; left:0; width: 100%; z-index: 1; background: $color-white; width: 100%; display: none;
  option{ margin: 0}
}

.bg-light-grey{background:$grey-lightest;}
.cont-center{width:800px; margin: 0 auto;
  @include respond(phone){
    width:100%; margin: 10px;
  }
}

.custom-list {
  border: 1px solid #ddd;
  border-radius: 0 0 5px 5px;
  border-top:0; position: absolute; top:36px; left:0; width: 100%; z-index: 1; background: $color-white; width: 100%; display: none;
  ul{
    li{padding: 4px 5px 0 7px;
      font-size: 1.3rem;
      color: #333;
      cursor: pointer; opacity: .7;
    &:last-child{
      padding-bottom: 5px;
      &:hover{border-radius: 0 0 5px 5px;}
    }
      &:hover{opacity: 1; background:$grey-lightest;}
    }
  }
}

//suggestion tag
.suggestion-tag{border-radius:16px; border: 1px solid $color-grey-bdr; display: inline-block; padding: 1px 10px; background: $grey-lightest; color: $color-text-grey; margin: 0 5px 5px 0; font-size: 1.2rem;
  &:hover, &.active{background: $color-cyan; color: $color-white; border-color: $color-cyan; cursor: pointer; transition: all ease .1s}
  i{display: inline-block;
    font-size: 1.4rem;
    transform: rotate(45deg);
    font-weight: bold;
    margin-left: 5px;}

}

//action-bar

.action-bar{margin-top: 20px; margin-bottom: 20px;}



//Alert listing
.alert-listing{margin-top: 20px;
  li{display: flex; justify-content: space-between; border-bottom: solid 1px $color-grey-light-4; margin-bottom: 10px;
    h4{
      p{margin-bottom: 5px;}
      span{font-size: 12px; opacity: .6}
    }
  }
}

//Success Message

.msg-success{background: $color-grey-light-4; padding: 10px; margin-bottom: 20px; position: relative;
  span{
    padding-right: 20px;
    display: inline-block;
    padding-left: 30px;
  }
  &:before{content:'\e812'; display: inline-block; margin-right: 10px; color:$color-white; background: $color-green; font-family: fontello; width: 20px; position: absolute;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 12px;}
  em{font-style: normal;}
  .icon-cancel{position: absolute; right: 0; top:0; opacity: .5; padding:7px}
}

.captcha-tranform{
  @include respond(tab-port){
    transform:scale(0.57);transform-origin:0;
  }
  @include respond(phone){
    transform:scale(0.94);transform-origin:0;
  }
}
