@font-face {
  font-family: 'fontello';
  src: url('../../fonts/fontello.eot?19403632');
  src: url('../../fonts/fontello.eot?19403632#iefix') format('embedded-opentype'),
  url('../../fonts/fontello.woff2?19403632') format('woff2'),
  url('../../fonts/fontello.woff?19403632') format('woff'),
  url('../../fonts/fontello.ttf?19403632') format('truetype'),
  url('../../fonts/fontello.svg?19403632#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../../fonts/fontello.svg?19403632#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-minus:before { content: '\e800'; } /* '' */
.icon-plus:before { content: '\e801'; } /* '' */
.icon-down-dir:before { content: '\e802'; } /* '' */
.icon-search:before { content: '\e803'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */
.icon-key:before { content: '\e805'; } /* '' */
.icon-mail:before { content: '\e806'; } /* '' */
.icon-user:before { content: '\e807'; } /* '' */
.icon-pencil:before { content: '\e808'; } /* '' */
.icon-edit:before { content: '\e809'; } /* '' */
.icon-doc:before { content: '\e80a'; } /* '' */
.icon-doc-1:before { content: '\e80b'; } /* '' */
.icon-edit-1:before { content: '\e80c'; } /* '' */
.icon-edit-2:before { content: '\e80d'; } /* '' */
.icon-warning-empty:before { content: '\e80e'; } /* '' */
.icon-warning:before { content: '\e80f'; } /* '' */
.icon-trash-empty:before { content: '\e810'; } /* '' */
.icon-cancel-circled-outline:before { content: '\e811'; } /* '' */
.icon-ok:before { content: '\e812'; } /* '' */
.icon-cancel:before { content: '\e813'; } /* '' */
.icon-plus-1:before { content: '\e814'; } /* '' */
.icon-down-open:before { content: '\e815'; } /* '' */
.icon-up-open:before { content: '\e816'; } /* '' */
.icon-briefcase:before { content: '\e817'; } /* '' */
.icon-phone:before { content: '\e818'; } /* '' */
.icon-phone-1:before { content: '\e819'; } /* '' */
.icon-heart:before { content: '\e81a'; } /* '' */
.icon-list:before { content: '\e81b'; } /* '' */
.icon-globe:before { content: '\e81c'; } /* '' */
.icon-chat:before { content: '\e81d'; } /* '' */
.icon-home:before { content: '\e81e'; } /* '' */
.icon-link:before { content: '\e81f'; } /* '' */
.icon-group:before { content: '\e820'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-euro:before { content: '\f153'; } /* '' */
.icon-pound:before { content: '\f154'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-calc:before { content: '\f1ec'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-calc:before { content: '\f1ec'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
