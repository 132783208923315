@mixin clearfix {

    &::after{
    content: "";
    display: table;
    clear: both;
    }
}

/*@mixin button{border-color: transparent;
  padding: 8px 20px !important;
  transition: all 0.3s ease 0s;
  font-size: $font-default-size;
  border-radius: 0;}*/

@mixin error{
  display: block;
  clear: both;
 /* padding: 4px 8px;*/
  font-size: 12px;
  /*font-weight: bold;*/
 /* border: 1px solid #EAD8AF !important;
  background: #FBF8DD;*/
  color: $color-orange;
  margin-top: 8px;
  position: relative;
  line-height: 1.33;
  margin-bottom: 8px;
}

@mixin rect-box{
  display: inline-block;
  clear: both;
  padding: 0px 8px 4px 8px;
  font-size: 12px;
   background: $color-white;
  position: relative;
  line-height: 1.33;

}

@mixin icon-prop{
  display: block; font-size: 2.2rem; font-family: fontello; position: absolute; left:15px; top:20px;
}

@mixin icon-menu{font-size: 2.2rem; font-family: fontello; position: relative; display: inline-block; margin-right: 10px;}

@mixin line{
  &:before, &:after{border-bottom:1px solid $color-grey-light-2;}
}


@mixin arrow-up{boder:1px solid #fff;
  &:before,&:after{
    bottom: 100%;
    left: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 9px;
    margin-left: -9px;
  }
  &:before {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #FFFFFF;
    border-width: 10px;
    margin-left: -10px;
  }
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 47.9em) { @content };    //767px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) { @content };     //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) { @content };    //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) { @content };    //1800
  }
}
