
.label-sml{font-size: 1.2rem; color: $color-black; display:block; }
label{ font-size: 1.4rem;
  &.help-text{text-transform: none;}
}
.form-control {
border-radius: 0;font-size: 1.4rem; height: 36px;box-shadow: inset 1px 2px 3px rgba(0,0,0,.1);

&::-webkit-input-placeholder {font-size:1.4rem; opacity:.7;}  /* WebKit, Blink, Edge */
&:-moz-placeholder {font-size:1.4rem; opacity:.7; }  /* Mozilla Firefox 4 to 18 */
&::-moz-placeholder {font-size:1.4rem; opacity:.7; }  /* Mozilla Firefox 19+ */
&:-ms-input-placeholder { font-size:1.4rem; opacity:.7; }  /* Internet Explorer 10-11 */
&::-ms-input-placeholder { font-size:1.4rem; opacity:.7; }  /* Microsoft Edge */

input{height:39px;}

}
.error-msg{@include error; margin-bottom: 10px;}
